<template>
  <b-row>
    <b-col lg="8" md="8" sm="12" cols="12">
      <b-card-actions
          ref="first-card"
          class="list-card"
          @refresh="refreshStop('first-card')"
      >
        <b-row class="mt-50 mb-5">
          <div class="card-header-row" :style="{backgroundColor: $store.state.colors.headerRowColor}">
            <b-col cols="12" class="d-flex justify-content-between">
              <h4 class="text-white d-inline-block card-title">{{ title }}</h4>
            </b-col>
          </div>
        </b-row>
        <b-table
            striped
            hover
            responsive
            class="position-relative departman-table mt-1"
            :per-page="perPage"
            :items="departmanList"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
        >
          <template #cell(departmanAdi)="data">
            {{ data.item.departmanAdi }}
          </template>
          <template #cell(departmanID)="data">
            <b-button
                variant="warning"
                class="btn-icon rounded-circle mr-50"
                size="sm"
                @click="editID = data.item.departmanID,departmanAdi = data.item.departmanAdi"
            >
              <font-awesome-icon icon="pen"/>
            </b-button>
            <b-button
                variant="danger"
                class="btn-icon rounded-circle mr-50"
                size="sm"
                @click="departmanRemove(data.item.departmanID)"
            >
              <font-awesome-icon icon="trash"/>
            </b-button>
          </template>
        </b-table>
      </b-card-actions>
    </b-col>
    <b-col lg="4" md="4" sm="12" cols="12">
      <b-card-actions
          ref="second-card"
          class="list-card"
          @refresh="refreshStop('second-card')"
      >
        <b-row class="mt-50 mb-5">
          <div class="card-header-row"
               :style="{backgroundColor: !editID ? $store.state.colors.headerRowColor: $store.state.colors.warningColor}"
          >
            <b-col cols="12" class="d-flex justify-content-between">
              <h4 class="text-white d-inline-block card-title">{{ titleTwo }}</h4>
            </b-col>
          </div>
        </b-row>
        <b-form-group
            label="Departman Adı"
            label-for="basicInput"
        >
          <b-form-input
              id="basicInput"
              v-model="departmanAdi"
              autocomplete="off"
              @keyup.enter="editID ? departmanUpdate() : departmanSave()"
          />
        </b-form-group>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="!departmanAdi"
            class="mr-50"
            :variant="editID ? 'primary' : 'success'"
            @click="editID ? departmanUpdate() : departmanSave()"
        >
          {{ editID ? 'Güncelle' : 'Kaydet' }}
        </b-button>
        <b-button
            v-if="editID"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            @click="departmanAdi = null, editID = null"
        >
          İptal
        </b-button>
      </b-card-actions>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import axios from 'axios'

export default {
  components: {
    BTable,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      editID: null,
      departmanAdi: null,
      fields: [
        {
          key: 'departmanAdi',
          label: 'Departman Adı',
          sortable: true,
          thStyle: {
            width: '60%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '60%' },
        },
        {
          key: 'departmanID',
          label: 'İşlemler',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '40%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '40%' },

        },
      ],
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      toplamKayit: 1,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    titleTwo() {
      return 'Departman Kaydı'
    },
    title() {
      return 'Departman Listesi'
    },
    departmanList: () => store.getters.GET_DEPARTMAN_LIST,
  },
  created() {
    this.departmanData()
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_VARDIYA_DETAY',
      action: 0,
    })
  },
  methods: {
    departmanUpdate() {
      const fd = this.postSchema()
      fd.append('methodName', 'departmanDuzenle')
      fd.append('departmanID', this.editID)
      fd.append('departmanAdi', this.departmanAdi)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.editID = null
              this.departmanAdi = null
              store.commit({
                type: 'SET_DEPARTMAN_LIST',
                action: 1,
                list: response.departmanList,
              })
            }
          })
    },
    departmanSave() {
      const fd = this.postSchema()
      fd.append('methodName', 'departmanKayit')
      fd.append('departmanAdi', this.departmanAdi)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.departmanAdi = null
              store.commit({
                type: 'SET_DEPARTMAN_LIST',
                action: 1,
                list: response.departmanList,
              })
            }
          })
    },
    departmanRemove(id) {
      const fd = this.postSchema()
      fd.append('methodName', 'departmanSil')
      fd.append('departmanID', id)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              if (this.editID === id) {
                this.editID = false
              }
              store.commit({
                type: 'SET_DEPARTMAN_LIST',
                action: 1,
                list: response.departmanList,
              })
            }
          })
    },
    departmanData() {
      const fd = this.postSchema()
      fd.append('methodName', 'departmanListesi')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              store.commit({
                type: 'SET_DEPARTMAN_LIST',
                action: 1,
                list: response.departmanList,
              })
            }
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'restoran')
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style>
.departman-table .table td {
  padding: 0.22rem 2rem !important;
}

</style>
